<template>
<div :class="$style.root">
  <span :class="$style.first">
    <span>{{ first }}</span>
  </span>
  <span v-if="count > 1" :class="$style.count">
    <VMenu left>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">(+{{ count - 1 }})</span>
      </template>
      <VList>
        <VListItem v-for="(item, index) in items" :class="$style.item" :key="index">
          <VListItemTitle :class="$style.title">{{ item }}</VListItemTitle>
        </VListItem>
      </VList>
    </VMenu>
  </span>
</div>
</template>

<script>
import { head } from 'lodash-es';
export default {
  name: 'CompactList',
  props: {
    items: { type: Array, default: () => [] },
  },
  computed: {
    first() {
      return head(this.items);
    },
    count() {
      return this.items.length || 0;
    }
  },
}
</script>

<style module lang="scss">
.root {
  white-space: nowrap;
  display: flex;
  width: 100%;
}
.item {
  min-height: 30px;
}
.first {
  position: relative;
  flex: 1 1 auto;
  min-height: 1.3em;
  overflow: hidden;
  span {
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
.count {
  flex: 0 0 auto;
  color: #f57f29;
  cursor: pointer;
}
.title {
  font-size: 14px;
}
</style>
