<template>
  <div :class="$style.root">
    <VDataTable
        fixed-header
        height="calc(100vh - 200px)"
        hideDefaultHeader
        :headers="headers"
        :items="listItems"
        :loading="loading"
        :footer-props="footer"
        :options.sync="options"
        :server-items-length="count"
        :item-class="getClassByRow"
        :mobile-breakpoint="0"
        @click:row="({ id }) => onDetail(id)"
        @update:page="page => onPagination({ page })"
        @update:items-per-page="size => onPagination({ size })"
    >
      <template v-slot:header>
        <thead class="v-data-table-header">
          <tr>
            <th v-for="{text, colspan } in headers" class="text-start" :colspan="colspan" :key="text">{{text}}</th>
          </tr>
          <FilterRow :items="headers" @input="onPagination" />
        </thead>
      </template>
      <template v-slot:item.inspection="{ item: { inspection } }">
        <CompactCeil :text="inspection" />
      </template>
      <template v-slot:item.status.name="{ item: { status } }">
        <CompactCeil :text="status.name" />
      </template>
      <template v-slot:item.company="{ item: { company } }">
        <CompactCeil :text="company" />
      </template>
      <template v-slot:item.author="{ item: { author } }">
        <CompactCeil :text="author" />
      </template>
      <template v-slot:item.workshopReviewer="{ item: { workshopReviewer } }">
        <CompactCeil :text="workshopReviewer" />
      </template>
      <template v-slot:item.reviewer="{ item: { reviewer } }">
        <CompactCeil :text="reviewer" />
      </template>
      <template v-slot:item.inspector="{ item: { inspector } }">
        <CompactCeil :text="inspector" />
      </template>
      <template v-slot:item.supply="{ item: { supply } }">
        <CompactCeil :text="supply" />
      </template>
      <template v-slot:item.workshop="{ item: { workshop } }">
        <CompactList :items="workshop" />
      </template>
      <template v-slot:item.products="{ item: { products } }">
        <CompactList :items="products" />
      </template>
      <template v-slot:item.type="{ item: { type } }">
        <CompactList :items="type" />
      </template>
    </VDataTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {map, get, toString, omitBy, isNull} from 'lodash-es';
import FilterRow from '@/components/inspect/FilterRow/FilterRow';
import CompactList from '@/components/inspect/ApplicationList/components/CompactList';
import CompactCeil from '@/components/inspect/ApplicationList/components/CompactCeil';
import { APPLICATION_STATUSES, USER_TYPES } from '@/store/inspect/enums';

export default {
  name: 'ApplicationList',
  components: {
    CompactList,
    CompactCeil,
    FilterRow,
  },
  props: {
    headers: { type: Array },
    items: { type: Array },
    count: { type: Number },
    page: { type: Number },
    size: { type: Number },
    loading: { type: Boolean },
  },
  data: function() {
    return {
      footer: {
        itemsPerPageText: '',
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
      },
      options: {
        page: this.page,
        itemsPerPage: this.size,
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/current',
      hasRole: 'user/hasRole',
    }),
    listItems() {
      if (!this.items) return [];
      return map(this.items, item => ({
        ...item,
        type: map(item.type, type => type.name),
        workshop: map(item.workshop, workshop => workshop.name),
        products: map(item.products, product => product.name),
      }));
    },
    isReviewer() {
      return this.hasRole(get(USER_TYPES, 'REVIEWER.value', 'REVIEWER'), 'inspect');
    },
    isWorkshopReviewer() {
      return this.hasRole(get(USER_TYPES, 'WORKSHOP_REVIEWER.value', 'WORKSHOP_REVIEWER'), 'inspect');
    },
    isInitiator() {
      return this.hasRole(get(USER_TYPES, 'INITIATOR.value', 'INITIATOR'), 'inspect');
    },
    isInspector() {
      return this.hasRole(get(USER_TYPES, 'INSPECTOR.value', 'INSPECTOR'), 'inspect');
    },
  },
  methods: {
    getClassByRow({ messages, status, persons }) {
      const user = toString(get(this.user, 'id'));
      if (this.isWorkshopReviewer && user === get(persons, 'workshopReviewer')) {
        if (get(APPLICATION_STATUSES, 'APPROVING_WORKSHOP.value') === status.value) return 'error lighten-4';
        if (get(APPLICATION_STATUSES, 'REWORK_WORKSHOP.value') === status.value) return 'orange lighten-4';
      }
      if (this.isReviewer && user === get(persons, 'reviewer')) {
        if (get(APPLICATION_STATUSES, 'APPROVING.value') === status.value) return 'error lighten-4';
        if (get(APPLICATION_STATUSES, 'REQUESTED_TO_REVOKE_INSPECTOR.value') === status.value) return 'error lighten-4';
        if (get(APPLICATION_STATUSES, 'AWAIT_REPLACEMENT_INSPECTOR.value') === status.value) return 'error lighten-4';
        if (get(APPLICATION_STATUSES, 'REQUESTED_TO_REVOKE_SLAVE_INSPECTOR.value') === status.value) return 'error lighten-4';
        if (get(APPLICATION_STATUSES, 'AWAIT_REPLACEMENT_SLAVE_INSPECTOR.value') === status.value) return 'error lighten-4';
        if (get(APPLICATION_STATUSES, 'REWORK.value') === status.value) return 'orange lighten-4';
      }
      if (this.isInitiator && user === get(persons, 'initiator')) {
        if (messages.active) return 'error lighten-4';
        if (get(APPLICATION_STATUSES, 'APPROVING.value') === status.value) return 'orange lighten-4';
        if (get(APPLICATION_STATUSES, 'REWORK.value') === status.value) return 'error lighten-4';
        if (get(APPLICATION_STATUSES, 'FINISH_REQUEST.value') === status.value) return 'error lighten-4';
        if (get(APPLICATION_STATUSES, 'APPROVING_WORKSHOP.value') === status.value) return 'orange lighten-4';
        if (get(APPLICATION_STATUSES, 'REWORK_WORKSHOP.value') === status.value) return 'error lighten-4';
      }
      if (this.isInspector && user === get(persons, 'inspector')) {
        if (messages.active) return 'error lighten-4';
      }
      return '';
    },
    onDetail(id) {
      this.$router.push({ name: 'inspect/ApplicationDetailView', params: { application: id }});
    },
    onPagination(params) {
      const { query } = this.$route;
      this.$router.push({ query: omitBy({ ...query, ...params }, isNull) });
    },
  }
}
</script>

<style module lang="scss">
.root {
  td {
    width: 500px;
    min-width: 130px;
    &:nth-child(1) { max-width: 50px; min-width: 50px; }
    &:nth-child(2) { max-width: 100px; }
    &:nth-child(3) { max-width: 105px; }
    &:nth-child(4) { max-width: 130px; }
    &:nth-child(5) { max-width: 100px; }
    &:nth-child(6) { max-width: 200px; }
    &:nth-child(7) { max-width: 180px; }
    &:nth-child(8) { max-width: 180px; }
    &:nth-child(9) { max-width: 180px; }
    &:nth-child(10) { max-width: 180px; }
    &:nth-child(11) { max-width: 200px; }
    &:nth-child(12) { max-width: 200px; }
    &:nth-child(13) { max-width: 180px; }
    &:nth-child(14) { max-width: 300px; }
  }
}
</style>
